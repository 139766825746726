import { Columns, Column, Stack, Inline } from '@bedrock-layout/primitives'
import { Icon } from '@okam/shared-ui'
import type { MotionValue } from 'framer-motion'
import { useTransform, useScroll, motion, useSpring } from 'framer-motion'
import Link from 'next/link'
import React from 'react'
import tw, { css } from 'twin.macro'
import { useMediaQuery } from 'usehooks-ts'
import BrandButton from '../BrandButton'
import BrandSearchInput from '../fields/BrandSearchInput'
import Heading from '../Heading'
import Img from '../Img'
import Paragraph from '../Paragraph'
import type { TAnimatedTeamProps } from './interface'
import { overlayStyles } from './styles'

const AnimatedTeam = (props: TAnimatedTeamProps) => {
  const { team, title, text, linkTitle, linkUrl, searchAriaLabel, searchPlaceholder, members, ...rest } = props
  const { scrollY } = useScroll()
  const transformConfig = { clamp: false }
  const springConfig = { damping: 20, stiffness: 300 }
  const isMobile = useMediaQuery('(max-width: 768px)')

  const transformValues = {
    column1: useSpring(useTransform(scrollY, [0, 3], [0, 0.1], transformConfig), springConfig),
    column2: useSpring(useTransform(scrollY, [0, 1], [0, -0.1], transformConfig), springConfig),
    column3: useSpring(useTransform(scrollY, [0, 3], [0, 0.1], transformConfig), springConfig),
    column4: useSpring(useTransform(scrollY, [0, 1], [0, -0.1], transformConfig), springConfig),
  }
  const membersData = members?.nodes?.map((member) => ({
    id: member.id,
    title: member.title,
    url: member.link,
  }))

  return (
    <Inline tw="mx-auto max-w-[1920px] bg-tertiary-1">
      <div tw="grid md:[grid-template-columns: 40% 1fr] z-10 lg:[height: 100vh] md:max-h-[32.5rem] xl:max-h-[55rem]">
        <div css={[tw`md:order-1`, tw`px-6 py-20 md:px-12 md:py-6 lg:px-14 2xl:py-24`]}>
          <div tw="grid content-center gap-4 h-full">
            {title && (
              <Heading variant="h3" as="h2" tw="font-semibold text-tertiary-5">
                {title}
              </Heading>
            )}
            {text && (
              <Paragraph variant="footnote" as="p">
                {text}
              </Paragraph>
            )}
            {linkUrl && linkTitle && (
              <BrandButton href={linkUrl} as="a" buttonStyle="outline" variant="black" tw="!px-0 mt-4 !justify-start">
                {linkTitle}
              </BrandButton>
            )}
            <div tw="mt-12 2xl:w-[80%]">
              <BrandSearchInput
                css={[tw`bg-transparent border-b`]}
                id="fuzzy-search"
                name="search"
                aria-label={searchAriaLabel ?? ''}
                placeholder={searchPlaceholder}
                dataFuzzySearch={membersData}
                icon={<Icon icon="Search" tw="!text-tertiary-3 ml-3" />}
              />
            </div>
          </div>
        </div>

        <div
          css={[
            tw`h-[60vh] md:order-2 md:w-[90%] md:h-auto md:max-h-[32.5rem] xl:max-h-[55rem]`,
            tw`relative overflow-hidden`,
            overlayStyles,
          ]}
          {...rest}
        >
          <Columns gutter="size3" columns={4} tw="md:relative md:bottom-[50px] lg:bottom-[400px]">
            <>
              {Object.entries(team).map(([key, value], index) => (
                <motion.div
                  key={key}
                  style={{
                    y: !isMobile
                      ? (transformValues[
                          `column${index + 1}` as keyof typeof transformValues
                        ] as MotionValue<number>) || 0
                      : 0,
                    transition: !isMobile ? 'transform 0.6s ease-out' : 'none',
                  }}
                >
                  <Column
                    span={1}
                    key={key}
                    tw="relative overflow-hidden"
                    css={css`
                      ${index % 2 === 0 ? 'margin-top: -10vh;' : 'margin-top: -20vh;'}
                    `}
                  >
                    <Stack gutter="size3">
                      {value.map((member, memberIndex) => {
                        const { image, url, alt } = member
                        return (
                          <Link
                            href={url}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${image}-${memberIndex}`}
                            tw="focus:outline-none focus-visible:border-2 focus-visible:border-primary-2"
                          >
                            <Img src={image} width={400} height={300} alt={alt} />
                          </Link>
                        )
                      })}
                    </Stack>
                  </Column>
                </motion.div>
              ))}
            </>
          </Columns>
        </div>
      </div>
    </Inline>
  )
}

export default AnimatedTeam
